<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list v-show="ctype[0] != 4" @setData="getData" iptType="1"></input-list>
      <div v-show="ctype[0] != 4">
        设备名称：
        <el-select ref="selectDev" @visible-change="$visibleChange($event, 'selectDev')" v-model="csbmc" placeholder="全部" clearable filterable>
          <el-option v-for="(item, index) in csbmcArr" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </div>
      <div v-show="ctype[0] == 4">
        设备名称:
        <el-select ref="selectDev" @visible-change="$visibleChange($event, 'selectDev')" v-model="personDev" placeholder="全部" clearable>
          <el-option :label="item.name" v-for="(item, index) in personDevArr" :key="index" :value="item.id"></el-option>
        </el-select>
      </div>
      <div>
        处理状态：
        <el-select v-model="cState" placeholder="全部" clearable>
          <el-option label="未处理" :value="0"></el-option>
          <el-option label="已处理" :value="1"></el-option>
        </el-select>
      </div>
      <div>
        查询时间：
        <el-date-picker v-model="value2" type="daterange" align="right" size="small" time-arrow-control key="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="display: inline-flex; width: 210px; height: 34px" value-format="yyyy-MM-dd"> </el-date-picker>
      </div>
      <span>
        <el-button v-if="$has(ctype[1])" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" @row-click="rows" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="101"></el-table-column>
        <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="codeDesc" label="异常类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="targetValue" label="异常值"></el-table-column>
        <el-table-column prop="adjust" label="处理状态" :formatter="adjust"></el-table-column>
        <el-table-column prop="handleContent" label="处理内容"></el-table-column>
        <el-table-column prop="createDt" label="产生时间" width="160"></el-table-column>
        <el-table-column prop="handleDt" label="处理时间" width="160"></el-table-column>
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <el-button @click="LooksCenterDialogVisible = true" type="primary" size="small" icon="el-icon-view">详 情</el-button>
            <el-button v-if="$has(ctype[2]) && scope.row.adjust == 0" type="danger" size="small" @click="deleteCenterDialogVisible = true" icon="el-icon-edit-outline">处理异常</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 详情模态框 -->
      <el-dialog title="查看异常预警" :visible.sync="LooksCenterDialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag center>
        <div class="dialogsC">
          <div class="newDialog">
            <div v-for="(i, n) in lookArr" :key="n">
              <span>{{ i.name }}</span>
              <el-input v-model="paramObj[i.model]" disabled clearable></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="LooksCenterDialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <!-- 处理异常模态框 -->
      <el-dialog title="处理异常数据" :visible.sync="deleteCenterDialogVisible" width="20%" :modal="true" :close-on-click-modal="false" @close="clears" v-dialogDrag center>
        <div class="dialogsC">
          <textarea cols="40" rows="7" v-model="exceptions" placeholder="请输入处理内容" class="deleteP"></textarea>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clears">取 消</el-button>
          <el-button type="primary" @click="deletes">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { recordexceptionQueryWithPagePersonal, recordexceptionUpdate, deviceQueryWithPage } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      cgxqy: '', //查询区域名称
      cjgmc: '', //查询机构名称
      cState: '', //查询处理状态
      csbmc: '', //查询设备名称
      csbmcArr: '', //查询设备名称数组
      LooksCenterDialogVisible: false, //控制查看模态框
      looksAreaName: '', // 查看区域名称
      looksCode: '', // 查看异常编号
      looksCodeDesc: '', // 查看异常名称
      looksCreateDt: '', // 查看创建时间
      looksHandleContent: '', // 查看处理内容
      looksHandleDt: '', // 查看处理时间
      bid: '', //id
      deleteCenterDialogVisible: false, //控制编辑模态框
      dadjust: '', // 校准异常
      exceptions: '', // 处理内容
      ctype: undefined, //查询设备类型
      user: '',
      lookArr: [
        { name: '区域名称：', model: 'areaName' },
        { name: '异常类型：', model: 'codeDesc' },
        { name: '产生时间：', model: 'createDt' },
        { name: '处理内容：', model: 'handleContent' },
        { name: '处理时间：', model: 'handleDt' },
      ],
      paramObj: {}, //查看对象
      personDevArr: [],
      personDev: '',
      cExceptionArr: [],
      value2: ['', ''],
    }
  },
  props: ['datas'],
  watch: {
    datas: {
      handler(newValue, oldValue) {
        this.ctype = newValue
      },
      deep: true,
      immediate: true,
    },
    // 监听日期类型
    value2(newVal) {
      if (newVal == null) {
        this.value2 = ['', '']
      }
    },
  },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.cgxqy = msg[0]
      this.cjgmc = msg[1]
      this.csbmc = ''
      this.csbmcArr = ''
      let params = {
        type: this.ctype[0],
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        pageSize: 10000,
      }
      deviceQueryWithPage(params).then((data) => {
        this.csbmcArr = data.result.records
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 获取当前行的所有信息
    rows(row) {
      this.bid = row.id
      this.paramObj = Object.assign({}, row)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        adjust: this.cState,
        organizationId: this.cjgmc,
        areaId: this.cgxqy,
        deviceId: this.csbmc || this.personDev,
        type: this.ctype[0],
        queryBeginDt: this.value2[0],
        queryEndDt: this.value2[1],
      }
      await recordexceptionQueryWithPagePersonal(params)
        .then((data) => {
          this.tableData = data.result.records || []
          this.tableData.forEach((v) => {
            v.areaName ||= '[暂未绑定]'
            v.organizationName ||= '[暂未绑定]'
          })
          this.totals = data.result.total
        })
        .catch(() => {})
      loading.close()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.cState = ''
      this.csbmcArr = ''
      this.csbmc = ''
      this.value2 = ['', '']
      this.searchs(1, 10)
    },
    // 取消
    clears() {
      this.deleteCenterDialogVisible = false
      this.exceptions = ''
    },
    // 处理状态转换
    adjust(row) {
      if (row.adjust == 0) {
        return '未处理'
      } else if (row.adjust == 1) {
        return '已处理'
      }
    },
    // 处理异常
    deletes() {
      let params = {
        id: this.bid,
        adjust: 1,
        handleContent: this.exceptions,
      }
      recordexceptionUpdate(qs.stringify(params)).then((data) => {
        this.$message.success(data.message)
        this.searchs(this.currpage, this.pagesize)
        this.deleteCenterDialogVisible = false
        this.exceptions = ''
      })
    },
    // 设备名称
    devArr() {
      let params = {
        type: 4,
        pageNo: 1,
        pageSize: 10000,
        // useFlag: 1,
      }
      deviceQueryWithPage(params).then((data) => {
        this.personDevArr = data.result.records
      })
    },
  },
  mounted() {
    if (this.ctype[0] == 4) {
      this.devArr()
    }
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.searchs(1, 10)
  },
}
</script>
<style src="../../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
  height: calc(100% - 50px);
}
</style>
